<template>
	<div>
		<header>
			<span class="back" @click="headback()">
				<i class="el-icon-arrow-left" style="font-size: 14px"></i>
				返回
				<i class="line">|</i>
			</span>
			<span class="titile">{{ spuid ? "编辑SPU" : "添加SPU" }}</span>
		</header>
		<div class="bindspu">
			<div class="bindspucon">
				<div class="bindsputitle">
					<h5>公共信息</h5>
				</div>
				<div class="bindsputop">
					<div class="bindspulist">
						<p>分组编号：<span v-text="groupnumber"></span></p>
					</div>
					<div class="bindspulist">
						<p>公共名称：<input type="text" v-model="shopname" /></p>
					</div>
				</div>
				<div class="bindsputable">
					<el-table :data="tableData" style="width: 100%" ref="multipleTable" row-key="id"
						@selection-change="singletable">
						<el-table-column type="selection" min-width="50"></el-table-column>
						<el-table-column min-width="180" label="商品编号">
							<template slot-scope="scope">
								<div class="sputext">
									<p>{{ scope.row.sku }}</p>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="商品名称" min-width="360">
							<template slot-scope="scope">
								<div class="sputext">
									<el-tooltip class="item" effect="dark" :content="scope.row.name" placement="top">
										<p>{{ scope.row.name }}</p>
									</el-tooltip>
								</div>
							</template>
						</el-table-column>
						<el-table-column min-width="260" v-for="(item, index) in changeNewGoods" :key="index">
							<template slot="header" slot-scope="scope">
								<span class="mustspu"><i>*</i>{{ item.title }}
									<b v-if="tableData && tableData.length" class="el-icon-delete"
										@click="delectattr(index)"></b></span>
							</template>
							<template slot-scope="scope">
								<div class="bindspuinput clearfloat">
									<input type="text" placeholder="请输入内容" v-model="item['content'][scope.$index].value"
										class="bindspufirst" />
									<input type="text" placeholder="顺序" v-model="item['content'][scope.$index].sort"
										class="bindspusx" />
								</div>
							</template>
						</el-table-column>
					</el-table>
					<div class="bindspuadd">
						<button @click="relevance()">关联商品</button>
						<button @click="addattribute()">添加属性</button>
						<button @click="delectshop()" :disabled="!hasPerm(['goods.spu.delete'])">
							删除商品
						</button>
					</div>
				</div>
				<diy-footer-button>
				    <el-button type="primary" @click="bc()" :disabled="!hasPerm(['goods.spu.update'])">保存</el-button>
				    <el-button  @click="headback()">取消</el-button>
				</diy-footer-button>
			</div>
		</div>
		<shopselector ref="shopList" @change="goodshoplist($event)"></shopselector>
		<!-- 添加规格属性 -->
		<el-dialog title="添加规格" class="attrtc" :visible.sync="addattrtype">
			<div class="rejectedbox">
				<div class="servicelist">
					<ul>
						<li>
							<p>规格名称：</p>
							<input type="text" v-model="attrtext" placeholder="请输入规格名称" />
						</li>
					</ul>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="addattrtype = false">取 消</el-button>
				<el-button type="primary" @click="attrdefine()">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				spuid: this.$route.params.id,
				checkedAll: false,
				groupnumber: "",
				shopname: "",
				tableData: [],
				changeNewGoods: [], //选择
				addattrtype: false,
				attrtext: "", //规格名称
				multipleSelection: [],
			};
		},
		components: {},
		computed: {},
		created() {},
		mounted() {
			this.loopedit();
		},
		methods: {
			loopedit() {
				this.$get(this.$apis.spuEdit + this.spuid)
					.then((res) => {
						if (res.code == 200) {
							this.groupnumber = res.data.spu_code;
							this.shopname = res.data.spu_name;
							this.tableData = res.data.spu_goods;
							this.$set(this, "changeNewGoods", res.data.spu_group);
						} else {
							this.common.message(this, res.message);
						}
					})
					.catch((err) => {
						// console.log(err)
						this.common.message(this, err.message);
					});
			},

			relevance() {
				this.$refs.shopList.shoptype = true;
			},

			goodshoplist(list) {
				this.$set(this, "tableData", [
					...this.tableData,
					...list.map((item) => ({
						id: item.id,
						sku: item.sku,
						name: item.name,
					})),
				]);
				// 转换格式
				list.forEach((ite) => {
					const newObj = {
						sort: "1",
						value: "",
					};
					// console.log(this.changeNewGoods)
					this.changeNewGoods.map((item) => {
						item.content.push({
							...newObj,
						});
						return item;
					});
				});
				// console.log(this.tableData)
			},
			// 添加商品属性
			addattribute() {
				if (this.tableData && this.tableData.length) {
					this.addattrtype = true;
				} else {
					this.$message({
						showClose: true,
						message: "请先选择要关联的商品",
						type: "warning",
						offset: 200,
						duration: 1000,
					});
				}
			},
			// 确认添加规格列
			attrdefine() {
				if (!this.attrtext) {
					this.$message({
						type: "warning",
						duration: 2000,
						message: "请填写规格名称",
					});
					return false;
				}
				let obj = this.changeNewGoods.filter((item) =>
					item.content.some((ite) => ite.value == "")
				);
				if (obj.length) {
					const cen = obj.filter((item) =>
						item.content.some((ite) => ite.value == "")
					);
					const cent = [];
					const arr = {
						title: this.attrtext,
						content: [{
								sort: "1",
								value: "",
							},
							{
								sort: "1",
								value: "",
							},
						],
					};
					for (let i = 0; i < cen[0].content.length; i++) {
						if (cen[0].content[i].value == "") {
							cent.push(cen[0].content[i]);
						}
					}
					arr.content = [...arr.content, ...JSON.parse(JSON.stringify(cent))];
					this.changeNewGoods.push(arr);
				} else {
					const arr = {
						title: this.attrtext,
						content: [],
					};
					for (let i = 0; i < this.tableData.length; i++) {
						arr.content.push({
							sort: "1",
							value: ""
						});
					}
					this.changeNewGoods.push(arr);
				}
				this.addattrtype = false;
				this.attrtext = "";
			},
			// 删除列
			delectattr(ind) {
				this.changeNewGoods.splice(ind, 1);
			},
			// 单个表格被选中
			singletable(val) {
				this.multipleSelection = val;
				if (val.length == this.tableData.length) {
					this.all_checked = true;
				} else {
					this.all_checked = false;
				}
			},

			// 选择表格(全选)
			changeAllSelect(val) {
				const loop = (data) => {
					data.forEach((item) => {
						item.is_checked = val;
						if ("indeterminate" in item) {
							item.indeterminate = false;
						}
						if (item.children) {
							loop(item.children);
						}
					});
				};
				loop(this.tableData);
			},
			// 删除商品
			delectshop() {
				this.idlist = [];
				if (!this.tableData.length) {
					this.$message({
						showClose: true,
						message: "请先选择要关联的商品",
						type: "warning",
						offset: 200,
						duration: 1000,
					});
					return false;
				}
				if (!this.multipleSelection.length) {
					this.$message({
						showClose: true,
						message: "请选择要删除的商品",
						type: "warning",
						offset: 200,
						duration: 1000,
					});
					return false;
				}
				for (let i = 0; i < this.multipleSelection.length; i++) {
					this.idlist.push(this.multipleSelection[i].id);
				}
				this.delectshopapi(this.idlist);
			},
			// 删除接口
			delectshopapi(id) {
				let data = {
					goods_id: id,
				};
				// console.log(this.changeNewGoods)
				// return false;
				this.$delete(this.$apis.delectAttr, data)
					.then((res) => {
						console.log(res);
						if (res.code == 200) {
							this.$message({
								type: "success",
								duration: 2000,
								message: "删除成功",
							});
							this.loopedit();
						} else {
							this.common.message(this, res.message);
						}
					})
					.catch((err) => {
						// console.log(err)
						this.common.message(this, err.message);
					});
			},
			// 保存
			bc() {
				let sublist = [];
				this.tableData.forEach((item, index) => {
					let attrlist = this.changeNewGoods.map((ite) => ({
						specs_key: ite.title,
						specs_value: ite.content[index].value,
						sort: ite.content[index].sort,
					}));
					sublist.push({
						goods_id: item.id,
						spec: attrlist
					});
				});
				if (!sublist.length) {
					this.$message({
						showClose: true,
						message: "请先选择要关联的商品添加商品属性",
						type: "warning",
						offset: 200,
						duration: 1000,
					});
					return false;
				}
				if (!this.changeNewGoods.length) {
					this.$message({
						showClose: true,
						message: "请添加商品属性",
						type: "warning",
						offset: 200,
						duration: 1000,
					});
					return false;
				}
				for (var i = 0; i < sublist.length; i++) {
					for (var j = 0; j < sublist[i].spec.length; j++) {
						if (!sublist[i].spec[j].specs_value || !sublist[i].spec[j].sort) {
							this.$message({
								showClose: true,
								message: "请填写完善商品属性",
								type: "warning",
								offset: 200,
								duration: 1000,
							});
							return false;
						}
					}
				}
				let data = {
					spu_code: this.groupnumber,
					spu_name: this.shopname,
					list: sublist,
				};
				// console.log(data)
				// return false;
				this.$put(this.$apis.attrEdit + this.spuid, data)
					.then((res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								duration: 1500,
								message: "保存成功",
								onClose: () => {
									this.$router.replace({
										path: "/goods/goods_spu",
									});
								},
							});
						} else {
							this.common.message(this, res.message);
						}
					})
					.catch((err) => {
						// console.log(err)
						this.common.message(this, err.message);
					});
			},

			headback() {
				window.history.go(-1);
			},
		},
	};
</script>

<style lang="scss">
	.mustspu i {
		color: #f56c6c;
	}

	.mustspu b {
		font-size: 16px;
		cursor: pointer;
		vertical-align: -1px;
		color: var(--fontColor, #fb6638);
		visibility: hidden;
		transition: all 0.3s;
	}

	.mustspu:hover b {
		visibility: visible;
	}

	.bindsputable .el-checkbox__inner {
		width: 18px;
		height: 18px;
	}

	.bindsputable .el-checkbox__inner::after {
		top: 3px;
		left: 6px;
	}

	.bindsputable .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
		top: 7px;
	}

	.attrtc .el-dialog {
		width: 500px !important;
		border-radius: 4px !important;
	}

	.rejectedbox {
		width: 100%;
		padding: 20px;
		box-sizing: border-box;
	}

	.rejectedbox h5 {
		font-size: 14px;
		color: #333;
		font-weight: 500;
		margin-bottom: 20px;
	}

	.rejectedbox h5 i {
		font-size: 20px;
		color: var(--warring, #e6a23c);
		float: left;
		margin-right: 6px;
		margin-top: 2px;
	}

	.rejectedbox h5 span {
		float: left;
		width: calc(100% - 26px);
	}

	.servicelist {
		width: 100%;
	}

	.servicelist ul li {
		width: 100%;
		margin-bottom: 10px;
	}

	.servicelist ul li:last-child {
		margin-bottom: 0;
	}

	.servicelist ul li p {
		font-size: 14px;
		color: #333;
		font-weight: 500;
		margin-bottom: 6px;
	}

	.servicelist ul li input {
		width: 100%;
		height: 38px;
		border-radius: 4px;
		padding: 0 12px;
		font-size: 14px;
		color: #333;
		outline: none;
		border: 1px solid rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}

	.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
		background: #535353;
	}

	.el-table--scrollable-x .el-table__body-wrapper {
		background: transparent;
	}

	.bindsputable .el-table th.el-table__cell {
		background: #fafafa;
		text-align: center;
		color: #333;
	}

	.bindsputable .el-table td.el-table__cell {
		text-align: center;
	}

	.bindspu {
		width: 100%;
		border-radius: 4px;
		background: #fff;
		padding: 20px;
		margin-bottom: 60px;
		box-sizing: border-box;

		.bindspucon {
			width: 100%;
			border: 1px solid #eee;
			box-sizing: border-box;

			.bindsputitle {
				width: 100%;
				padding: 10px;
				box-sizing: border-box;
				border-bottom: 1px solid #eee;
				background: #fafafa;

				h5 {
					font-size: 14px;
					color: #333;
				}
			}

			.bindsputop {
				width: 100%;

				.bindspulist {
					width: 100%;
					padding: 10px;
					box-sizing: border-box;
					border-bottom: 1px solid #eee;

					p {
						font-size: 14px;
						color: #333;

						input {
							display: inline-block;
							width: calc(100% - 74px);
							height: 38px;
							border-radius: 4px;
							padding: 0 14px;
							font-size: 14px;
							color: #333;
							outline: none;
							border: 1px solid #c0c4cc;
							box-sizing: border-box;
						}
					}
				}
			}

			.bindsputable {
				width: 100%;

				.sputext {
					width: 100%;

					p {
						font-size: 14px;
						color: #333;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}

				.bindspuinput {
					width: 100%;
					margin: 0 auto;

					input {
						display: inline-block;
						width: 49%;
						height: 38px;
						text-align: center;
						font-size: 14px;
						color: #333;
						padding: 0 10px;
						outline: none;
						border-radius: 4px;
						border: 1px solid #c0c4cc;
						box-sizing: border-box;
					}

					.bindspufirst {
						width: 80%;
						text-align: left;
						border-radius: 4px 0 0 4px;
						border-right: 0;
					}

					.bindspusx {
						width: 20%;
						border-radius: 0 4px 4px 0;
					}
				}

				.bindspuadd {
					width: 100%;
					padding: 10px 0;
					box-sizing: border-box;
					font-size: 0;
					text-align: center;

					button {
						display: inline-block;
						padding: 0 16px;
						height: 32px;
						line-height: 30px;
						border: 1px solid var(--fontColor, #fb6638);
						font-size: 14px;
						color: var(--fontColor, #fb6638);
						box-sizing: border-box;
						border-radius: 4px;
						margin-right: 10px;
						transition: all 0.3s;
						background: transparent;

						&:last-child {
							margin-right: 0;
						}

						&:hover {
							background: var(--fontColor, #fb6638);
							color: #fff;
						}
					}
				}
			}
		}
	}
</style>
<style lang="scss" scoped>
	header {
		margin-bottom: 20px;
	}
</style>
